// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "chartkick/chart.js"

import "bootstrap"
import 'bootstrap-icons/font/bootstrap-icons.css'
import "datatables.net-bs5"
import "datatables.net-buttons-bs5"
import "datatables.net-searchbuilder-bs5"
import jsZip from 'jszip';
import "datatables.net-buttons/js/buttons.colVis"
import "datatables.net-buttons/js/buttons.html5"
import "datatables.net-buttons/js/buttons.print"
import "../stylesheets/application"

window.$ = $
window.JSZip = jsZip;

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require('./select2')

let dtable = ""
let dttable = ""

$(document).on("turbolinks:load",function(){
    dtable = $('.dtable').DataTable({
        "pageLength": 13,
        dom: 
        '<"row justify-content-between mb-2 align-items-center"'
            +'<"col-md-auto col-12"B>'
            +'<"col-md-auto col-12 text-center"l>'
            +'<"col-md-auto col-12"f>'
        +'>'
        +'<"table-responsive"t>'
        +'<"d-flex justify-content-between align-items-center"ip>',
        buttons: [
            {
                extend: 'excel',
                titleAttr: 'Export to Excel',
                exportOptions: {
                    columns: ':visible',
                }
            },
            {
                extend: 'csv',
                titleAttr: 'Export to CSV',
                exportOptions: {
                    columns: ':visible',
                }
            },
            'colvis'
        ]
    });

    dttable = $('.dttable').DataTable({
        "pageLength": 13,
        dom: 
        '<"row justify-content-between mb-2 align-items-center"'
            +'<"col-md-auto col-12 text-center"l>'
            +'<"col-md-auto col-12"f>'
        +'>'
        +'<"table-responsive"t>'
        +'<"d-flex justify-content-between align-items-center"ip>'
    });
    
    dtable.buttons().container().appendTo( '.col-md-6:eq(0)' );

})

document.addEventListener("turbolinks:before-cache", function () {
    if (dtable !== null) {
        dtable.destroy();
        dtable = null;
    }
    if (dttable !== null) {
        dttable.destroy();
        dttable = null;
    }
});