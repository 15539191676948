import 'select2'
import 'select2/dist/css/select2.css'
import 'select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.min.css'

$(document).on("turbolinks:load",function(){
  $('.select2').select2({
      theme: "bootstrap-5",
      placeholder: "Please Select",
      allowClear: true,
      width: '100%'
  });

  $('.nested_select2').select2({
    theme: "bootstrap-5",
    placeholder: "Select Item",
    allowClear: true,
    width: '100%'
  });
})

$(document).on('select2:open', () => {
  document.querySelector('.select2-search__field').focus();
});